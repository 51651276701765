import React, { useEffect, useState } from 'react';
import {
  getInteriorCodeFromFactory,
  getInteriorCodeFromOptions,
  getMakeNameByCode,
  getVarnishCodeFromFactory,
  getVarnishCodeFromOptions,
  IConfiguration,
  INextArticle,
  IOrder,
  IReserveDetails,
  ISigningJobInfo,
  IVehicle,
  IVehicleConfiguration,
  IVehicleOption,
  MAKE,
  OrderStatus,
} from 'next-common';
import { OrderButtons } from './OrderButtons';
import { VehicleInfo } from './VehicleInfo';
import { OrderStatusList } from './OrderStatusList';
import { OrderPaneArea, OrderPaneContainer, OrderPaneHeader, OrderPaneStyled, PaneTitle } from './OrderPane.styled';
import {
  checkStatus,
  getModelName,
  getModelSeries,
  getStructuredVehicleData,
  hasReservationWaitingState,
  isOldOfferExpire,
  getUpdatedOffTaxForVehicle,
  prepareImageObject,
  prepareInitialArticlesAndCart,
  prepareInitialCartData,
  removeDuplicateObjectFromArray,
  VehicleConfig,
} from '../../../../utils';
import { ICartPrices, ICartProps, ICartSummaryData } from '@next-components/cart';
import { IModelPackages, IVehicleModel } from '../../sales-vehicle-picker-page';
import { AccessoryTool } from '../../sales-vehicle-accessories-page/AccessoryTool';
import { IEquipmentIdAndSKU } from 'sales-crystallize-common';
import { ToastArea } from '../../sales-common/ToastArea';
import { AgreementInformation } from './AgreementInformation';
import { useMutation } from '@apollo/client';
import { IVehicleChargeStatus, sendCancelConfirmOrderMutationParsed, useVatStateCalculationHandler, useVehicleData } from '../../../../common';
import { IAlertPopup } from '../../sales-wizard';
import { LoadingSpinner } from '@next-components/loading-spinner';

interface IOrderProps {
  order: IOrder;
  vehicleConfig: IVehicleConfiguration;
  vehicleList: IVehicleConfiguration;
  configuration?: IConfiguration;
  isUnavailablePimData?: boolean;
  isVehicleValidForReserveOrSign?: boolean;
  vehicleAvailabilityCode?: string;
  isSelectedVehicleUnavailable?: boolean;
  dealerRole?: string;
}

export interface ICommonSalesOfferPdfProps {
  vatPrices: ICartPrices;
}

export const isOffer = (status: OrderStatus, includeCancelled = false, includeExpired = false) =>
  checkStatus(
    [OrderStatus.CREATED, OrderStatus.DRAFT_CREATED, OrderStatus.PAUSED, OrderStatus.FAILURE, OrderStatus.SIGNED],
    status,
    includeCancelled,
    includeExpired,
  );

export const isOrder = (status: OrderStatus, includeCancelled = false) =>
  checkStatus([OrderStatus.COMPLETED, OrderStatus.CONFIRMED], status, includeCancelled);

export const isCancelOrder = (status: OrderStatus) =>
  checkStatus([OrderStatus.CANCEL_ORDER_SUCCESS], status);

export const isIfsEventPending = (status: OrderStatus) =>
  checkStatus([OrderStatus.SIGNED, OrderStatus.CANCEL_ORDER_PENDING], status);

export const isShowServiceContractLink = (status: OrderStatus, order: IOrder, signingJob: ISigningJobInfo) => {
  const validOrderStatus = checkStatus(
    [OrderStatus.SIGNED, OrderStatus.CONFIRMED, OrderStatus.COMPLETED, OrderStatus.FAILURE],
    status,
  );
  const serviceContractStatus = ['completed', 'expired'];
  const signedStatus =
    !!signingJob && serviceContractStatus?.includes(signingJob?.signicatSigningStatus?.toLowerCase());
  return order?.isServiceContractSelected && validOrderStatus && signedStatus;
};

export const isShowSignedContractLink = (status: OrderStatus, order: IOrder, signingJob: ISigningJobInfo) => {
  const validOrderStatus = checkStatus(
    [OrderStatus.SIGNED, OrderStatus.CONFIRMED, OrderStatus.COMPLETED, OrderStatus.FAILURE],
    status,
  );
  const signingContractStatus = ['completed', 'expired'];
  const signedStatus = signingContractStatus?.includes(signingJob?.signicatSigningStatus?.toLowerCase());
  return (
    !order?.isServiceContractSelected &&
    signingJob?.signedDocumentName?.includes('_signed') &&
    validOrderStatus &&
    signedStatus
  );
};

export const OrderPane = (props: IOrderProps) => {
  const { vehicleConfig, order, vehicleList } = props;
  const [status, setStatus] = useState(props?.order?.status);
  const [accTool, setAccTool] = useState<AccessoryTool>(null);
  const [cartData, setCartData] = useState<ICartProps>(null);
  const [vehicleColorId, setVehicleColorId] = useState<string>(null);
  const [vehicleInteriorId, setVehicleInteriorId] = useState<string>(null);
  const [vehicleReserveStatus, setVehicleReserveStatus] = useState(
    hasReservationWaitingState(props?.order?.reserveDetails),
  );
  const [mappedEquipment, setMappedEquipment] = useState<IEquipmentIdAndSKU[]>([]);
  const [selectedVariant, setSelectedVariant] = useState<IModelPackages>(null);
  const [selectedModel, setSelectedModel] = useState<IVehicleModel>(null);
  const [vehicleChargeStatus, setVehicleChargeStatus] = useState<IVehicleChargeStatus>(null);
  const [notifications, setNotifications] = React.useState<JSX.Element[]>([]);
  const [articles, setArticles] = React.useState<INextArticle[]>([]);
  const [orderEditButtonDisabled, setOrderEditButtonDisabled] = React.useState<boolean>(false);
  const isSigned = checkStatus([OrderStatus.SIGNED], props?.order?.status);
  const make: string = vehicleConfig?.model?.make;
  const series: string = vehicleConfig?.model?.series;
  const isOfferExpire: boolean = isOldOfferExpire(
    props?.order?.status,
    props?.order?.vatCalculation?.salesTotalExcludingVatAndTax,
  );
  const seriesName: string = props.order?.lead?.subFamilySeries || props.order?.pimSnapshot?.series || props.vehicleConfig?.model?.series;
  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const [cancelConfirmOrderMutation] = useMutation(sendCancelConfirmOrderMutationParsed);
  const [isShowCancelConfirmOrderModel, setIsShowCancelConfirmOrderModel] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const updateColorAndInterior = (options: IVehicleOption[]) => {
    const updatedOptions = JSON.parse(JSON.stringify(options));
    setVehicleColorId(getVarnishCodeFromOptions(updatedOptions) || null);
    setVehicleInteriorId(updatedOptions?.[0]?.packageInteriorId || getInteriorCodeFromOptions(updatedOptions) || null);
  };

  const { data: selectedVehicleData, loading: vehicleDataLoading} = useVehicleData({
    serialNo: order?.vehicles?.[0]?.serialNo,
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    //Create updated articles for check one-off tax is changed or not
    if(selectedVehicleData && articles?.length) {
      const data = getUpdatedOffTaxForVehicle(props.order, props.configuration, selectedVehicleData, articles);
      setVehicleChargeStatus(data);
    }
  }, [selectedVehicleData, articles])

  useEffect(() => {
    if (!order || !vehicleConfig) return;
    if (isOfferExpire) {
      setAccTool(new AccessoryTool(order, vehicleConfig, [], [], false));
    }
  }, [order, vehicleConfig]);

  useEffect(() => {
    const orderedVehicle: IVehicle[] = order?.vehicles;
    if (orderedVehicle?.[0]?.serialNo === '') {
      const factoryDetail = orderedVehicle?.[0]?.orderFromFactoryDetails;
      setVehicleColorId(getVarnishCodeFromFactory(factoryDetail) || null);
      setVehicleInteriorId(getInteriorCodeFromFactory(factoryDetail) || null);
    } else if (orderedVehicle?.[0]?.serialNo || order?.options?.length > 0) {
      updateColorAndInterior(order?.options);
    } else {
      setVehicleColorId(null);
      setVehicleInteriorId(null);
    }
    setStatus(props?.order?.status);
  }, [order]);

  useEffect(() => {
    const modelData: IVehicleModel[] = getStructuredVehicleData(vehicleList);
    modelData?.forEach((model) => {
      model?.salesPackages?.forEach((variant: IModelPackages) => {
        if (variant?.id === vehicleConfig?.salesPackages?.[0]?.id) {
          setMappedEquipment(variant?.mappedEquipment ?? []);
          setSelectedVariant(variant);
          setSelectedModel(model);
        }
      });
    });
  }, [vehicleList, vehicleConfig]);

  //Prepares InitialCartData and for Orders without VAT
  useEffect(() => {
    if (isOfferExpire) {
      const initialCartData = prepareInitialCartData(
        selectedVariant,
        selectedModel,
        order,
        accTool,
        vehicleConfig,
        props?.configuration,
      );
      const prices = null;
      setCartData({
        ...cartData,
        make: getModelName(vehicleConfig, make),
        modelSeries: getModelSeries(vehicleConfig, series),
        data: removeDuplicateObjectFromArray<ICartSummaryData>([...initialCartData], 'key'),
        prices,
        image: prepareImageObject(vehicleConfig?.images?.[0]),
      });
    }
  }, [accTool, order, vehicleConfig, selectedVariant, selectedModel]);

  //Prepares InitialCartData and InitialArticles for Orders with VAT
  useEffect(() => {
    if (!isOfferExpire) {
      const { initialCart, initialArticles } = prepareInitialArticlesAndCart(
        order,
        vehicleConfig,
        vehicleColorId,
        vehicleInteriorId,
        props?.configuration,
      );
      const isModelVariantAvailable = checkForModelVariantAvailablity(initialCart, selectedVariant);
      const prices = null;

      if (isModelVariantAvailable) {
        setCartData({
          ...cartData,
          make: getModelName(vehicleConfig, make),
          modelSeries: getModelSeries(vehicleConfig, series),
          data: removeDuplicateObjectFromArray<ICartSummaryData>([...initialCart], 'key'),
          prices,
          image: prepareImageObject(vehicleConfig?.images?.[0]),
        });
      }
      setArticles(initialArticles);
      // TODO: Enable when we get clarity
      //const changedPrice = isPimPriceChanged(initialCart, order, vehicleConfig, selectedVariant);
      // if (changedPrice?.length > 0) {
      //   setNotifications([
      //     <PimWarning
      //       priceChangedFields={changedPrice}
      //       key="pimPriceChange"
      //       onClose={() => setNotifications([])}
      //     />,
      //   ]);
      // }
    }
  }, [order, vehicleConfig, vehicleColorId, vehicleInteriorId, selectedVariant]);

  const checkForModelVariantAvailablity = (cartData: ICartSummaryData[], selectedVariant: IModelPackages) => {
    if (!cartData || selectedVariant?.sku !== vehicleConfig?.sku) return;
    const modelVariantKeyList: string[] = [VehicleConfig?.MODEL, VehicleConfig?.VARIANT];
    return cartData?.findIndex((item) => modelVariantKeyList?.includes(item?.key)) >= 0;
  };

  const onStatusChange = (status: OrderStatus) => {
    setStatus(status);
  };

  const onChangeReservationStatus = (reserveDetails: IReserveDetails) => {
    setVehicleReserveStatus(hasReservationWaitingState(reserveDetails));
  };

  const { finalPrice } = useVatStateCalculationHandler({
    articles,
    carType: order?.pimSnapshot?.vehicleType ?? vehicleConfig?.model?.vehicleType,
  });

  const getMake = (makeCode: string = null) => {
    if (!makeCode) return 'Ukjent merke';
    const makeEnumValue = Object.keys(MAKE)?.find((makeKey) => MAKE[makeKey] === makeCode);
    return getMakeNameByCode(MAKE[makeEnumValue], true);
  };

  const cancelConfirmOrderModalData: IAlertPopup = {
    title: 'Er du sikker på at du vil annullere ordren?',
    description: 'Ordren annulleres og bilen legges tilbake pa eget lager. Denne handlingen kan ikke angres.',
    actionsList: [
      {
        label: 'Annuller ordre',
        actionMethod: () => {
          customerOrderCancel()
          setIsShowCancelConfirmOrderModel(false)
        }
      },
      {
        label: 'Avbryt',
        actionMethod: () => setIsShowCancelConfirmOrderModel(false),
        actionVariant: 'secondary',
      },
    ],
  };

  const customerOrderCancel = async () => {
    setIsLoading(true);
    try {
      await cancelConfirmOrderMutation({
        variables: {
          id: props.order.id,
          customerId: props.order.customer?.reference?.bosApiId,
        },
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  }

  if (isLoading || vehicleDataLoading) {
    return <LoadingSpinner isModal={true} />;
  }

  return (
    <div>
      <OrderPaneContainer>
        <OrderPaneHeader>
          <OrderStatusList
            order={props?.order}
            onStatusChange={onStatusChange}
            onChangeReservationStatus={onChangeReservationStatus}
            configuration={props.configuration}
            setOrderEditButtonDisabled={setOrderEditButtonDisabled}
            isUnavailablePimData={props.isUnavailablePimData}
            setPageLoading={setPageLoading}
            isVehicleValidForReserveOrSign={props.isVehicleValidForReserveOrSign}
            vehicleAvailabilityCode={props.vehicleAvailabilityCode}
            isSelectedVehicleUnavailable={props.isSelectedVehicleUnavailable}
            showCancelConfirmOrderModel={isShowCancelConfirmOrderModel}
            cancelOrderModalData={cancelConfirmOrderModalData}
            openCancelConfirmModal={() => !isShowCancelConfirmOrderModel && setIsShowCancelConfirmOrderModel(true)}
            vehicleChargeStatus={vehicleChargeStatus}
          />
        </OrderPaneHeader>

        <OrderPaneStyled>
          <div className="pane paneHeader">
            <div className="cartHeader">
              <div className="carBrandDetail">
                <p className="carBrand">{`${getMake(order?.lead?.make)}`}</p>
                <h2 className="carModel">{seriesName}</h2>
              </div>
              <div className="carImage">
                <img src={cartData?.image?.defaultUrl || cartData?.image?.url} alt={cartData?.image?.altText} />
              </div>
            </div>
          </div>
          <div className="pane">
            <PaneTitle>
              <span>Bilens konfigurasjon</span>
            </PaneTitle>
            <OrderPaneArea>
              <VehicleInfo
                order={props?.order}
                vehicleConfig={props?.vehicleConfig}
                cartData={cartData}
                vatPrices={finalPrice}
                vehicleChargeStatus={vehicleChargeStatus}
              />
            </OrderPaneArea>
          </div>
          <div className="pane">
            <PaneTitle>
              <span>Informasjon om avtalen</span>
            </PaneTitle>
            <AgreementInformation order={props?.order} />
            <OrderButtons
              status={status}
              order={order}
              vehicleConfig={vehicleConfig}
              onStatusChange={onStatusChange}
              vehicleOrderStatus={props?.order?.vehicleOrderStatus}
              vehicleReserveStatus={vehicleReserveStatus}
              isSigned={isSigned}
              cartData={cartData}
              mappedEquipment={mappedEquipment}
              configuration={props?.configuration}
              vatPrices={finalPrice}
              orderEditButtonDisabled={orderEditButtonDisabled}
              pageLoading={pageLoading}
              articles={articles}
              carType={order?.pimSnapshot?.vehicleType ?? vehicleConfig?.model?.vehicleType}
              dealerRole={props?.dealerRole}
              isTaxChange={vehicleChargeStatus?.isTaxChange}
              selectedVehicleData={selectedVehicleData}
              setNotifications={setNotifications}
            />
          </div>
        </OrderPaneStyled>
      </OrderPaneContainer>
      <ToastArea notifications={notifications}></ToastArea>
    </div>
  );
};
