import React, { useEffect, useState } from 'react';
import { TableVirtuoso } from 'react-virtuoso';
import { DynamicListStyled, TableWrapper } from './DynamicListStock.styled';
import { ListHeaderStock, VehicleListItemStock } from './parts';
import { EmptyList } from '../DynamicListMer/parts';
import { IDynamicListStockTableData } from './IDynamicListStock';

const DynamicListDisplayRecord = ({
  props,
  isLoading,
  config,
  onButtonClickDetailPage,
  isRowSelected,
  onElementClick,
  toShow,
  filter,
  selectedSerialNumber,
  removeVehicle,
  isVehicleReserved,
}) => {
  const [items, setItems] = useState(toShow);

  const [sortDirection, setSortDirection] = useState('asc');
  const [isColumnClicked, setIsColumnClicked] = useState(false);

  useEffect(() => {
    const updatedItems = toShow?.map((i) => ({ ...i, isOpen: false }));
    updatedItems?.sort((a, b) => {
      if (a?.serialNumber === selectedSerialNumber) return -1; 
      if (b?.serialNumber === selectedSerialNumber) return 1;
      return 0;
    });
    setItems(updatedItems);
  }, [toShow, selectedSerialNumber]);


  const handleSort = (column: string) => {
    if (column === 'trackTraceCode') {
      const newDirection = sortDirection === 'asc' ? 'desc' : 'asc';
      setSortDirection(newDirection);
      setIsColumnClicked(true);

      const sortedItems = [...items].sort((a, b) => {
        if (a?.serialNumber === selectedSerialNumber) return -1;
        if (b?.serialNumber === selectedSerialNumber) return 1;

        const trackTraceCodeA = a?.trackTraceCode ?? 0;
        const trackTraceCodeB = b?.trackTraceCode ?? 0;
        if (newDirection === 'asc') {
          return trackTraceCodeA - trackTraceCodeB;
        } else {
          return trackTraceCodeB - trackTraceCodeA;
        }
      });

      setItems(sortedItems);
    }
  };
  
  const renderItem = ({ rowItem, index }) => {
    return (
      <div key={`list_mer_item_${rowItem?.serialNumber || ''}_${rowItem?.serialNumber}`}>
        <VehicleListItemStock
          isVehicleReserved={isVehicleReserved}
          data={rowItem}
          cols={config?.cols}
          detailsCols={props?.detailsCols}
          withCheckbox={config?.withCheckbox}
          selectable={config?.selectable}
          selected={isRowSelected(rowItem)}
          onElementClick={onElementClick}
          disabled={rowItem?.isDisable}
          withAccordion={config?.withAccordion}
          isLoading={isLoading}
          onButtonClickDetailPage={onButtonClickDetailPage}
          filter={filter}
          isRowSave={rowItem?.serialNumber === selectedSerialNumber}
          removeVehicle={removeVehicle}
          index={index}
          isOpen={rowItem?.isOpen}
          onAccordionClick={(index, _size) => {
            setItems(
              items?.map((i, key) => ({
                ...i,
                isOpen: key === index ? !i.isOpen : i.isOpen,
              })),
            );
          }}
        />
      </div>
    );
  };

  return (
    <DynamicListStyled isNewDynamicList={props?.isNewDynamicList}>
      <TableWrapper height={props?.height} isNewDynamicList={props?.isNewDynamicList} className="tableContainer">
        <div className="scrollbarHide">
          <TableVirtuoso
            data={items}
            style={{ height: 800 }}
            fixedHeaderContent={() => (
              <tr>
                <ListHeaderStock
                  noHeader={config?.noHeader}
                  selectable={config?.selectable}
                  withCheckbox={config?.withCheckbox}
                  cols={config?.cols}
                  withAccordion={config?.withAccordion}
                  isNewDynamicList={props?.isNewDynamicList}
                  onSortChange= {handleSort}      
                  sortDirection = {sortDirection}    
                  isColumnClicked = {isColumnClicked}
                />
              </tr>
            )}
            itemContent={(index: number, rowItem: IDynamicListStockTableData) => (
              <td style={{ width: '100vw', padding: 0 }}>{renderItem({ rowItem, index })}</td>
            )}
            components={{
              EmptyPlaceholder: () => <EmptyList className="sellAllCars" />,
            }}
          />
        </div>
      </TableWrapper>
    </DynamicListStyled>
  );
};

export default DynamicListDisplayRecord;
