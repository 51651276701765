import React from 'react';
import { Messagebar, MessagebarSeverityLevel } from '@next-components/messagebar';
import { WarningStyled } from './Warning.styled';
import { getErrorMessageFromNextError } from './NextErrorMessageMapper';
import { IValidationToastNotification, Routes } from '../../common';
import { getAppendingNumericIdFromUrl } from '../../utils';
import { OrderViewTabs } from '../sales/sales-common/enums';
import { useNavigate } from 'react-router-dom';

/**
 * Catch error from server, present to user nicley wrapped.
 * Supports types of NextFunctionalError
 */
export interface INextErrorNotificationProps extends IValidationToastNotification {
  errorCode: number;
  orderId?: string;
  hideLink?: boolean;
}

export const NextErrorNotification = (props: INextErrorNotificationProps) => {
  const getOrderId = () => {
    if (props?.hideLink) return null;
    return props.orderId || getAppendingNumericIdFromUrl('')
  };
  const navigate = useNavigate();

  const redirectToEvents = async () => {
    const route = Routes.getViewPage(getOrderId());
    navigate(`${route}?tab=${OrderViewTabs.EVENTS}`);
  };

  return (
    <Messagebar severityLevel={props.severityLevel} title={'Handling feilet'} isOpen onClose={props?.onClose}>
      <WarningStyled>
        <p>{getErrorMessageFromNextError(props.errorCode)}</p>
        {getOrderId() && (
          <>
            <p id={'information'}>Ytterligere informasjon finnes her: </p>
            <p id={'event-link'} onClick={() => redirectToEvents()}>
              Hendelser
            </p>
          </>
        )}
      </WarningStyled>
    </Messagebar>
  );
};

NextErrorNotification.defaultProps = {
  severityLevel: MessagebarSeverityLevel.WARNING,
};
