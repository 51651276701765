import { useQuery, WatchQueryFetchPolicy } from '@apollo/client';
import { IVehicleStockEcomItem } from 'sales-common';
import { ERROR_POLICY } from '../constants';
import { searchVehicleBySerialNumberParsed } from '../graphql';

export interface IVehicleDataParams {
  serialNo: string;
  fetchPolicy?: WatchQueryFetchPolicy;
}

export interface IVehicleResult {
  data: IVehicleStockEcomItem;
  loading: boolean;
}

export const useVehicleData = (params: IVehicleDataParams): IVehicleResult => {
  const { data, loading } = useQuery(searchVehicleBySerialNumberParsed, {
    variables: {
      serialNo: params?.serialNo,
    },
    errorPolicy: ERROR_POLICY,
    skip: !params?.serialNo,
    fetchPolicy: params?.fetchPolicy || 'cache-first',
  });

  return { data: data?.searchVehicleBySerialNumber, loading };
};
