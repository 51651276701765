import { addDays, differenceInDays, format, isValid } from 'date-fns';
import { isEmpty, isNull } from 'lodash';
import {
  IBosApiAttachmentData,
  IOrder,
  IPimVehicleModel,
  IReference,
  IVehicleConfiguration,
  OrderSource,
  OrderStatus,
} from 'next-common';
import { ICartSummaryData } from '@next-components/cart';
import { maxExpiryLength } from '../../common';

export const getAppendingIdFromUrl = (appendingPath = '', pathname = window.location.pathname) => {
  const url = pathname?.replace(appendingPath, '')?.replace(/\/$/, '') || '';
  return url.substring(url.lastIndexOf('/') + 1);
};

export const getAppendingNumericIdFromUrl = (appendingPath = '', pathname = window.location.pathname) => {
  const id = getAppendingIdFromUrl(appendingPath, pathname);
  const expression = /^\d{17,18}/gm;
  return new RegExp(expression)?.test(id) ? id : null;
};

export const getPimCodeFromOrderOrVehicle = (order: IReference, vehicle: IVehicleConfiguration) =>
  (order?.pimCode) || (vehicle?.sku) || null;

export const findExpiresAt = (expiresAt: string, createdAt: string, expiryLength: number = maxExpiryLength): Date => {
  let expriesMoment = new Date(expiresAt);
  if (isValid(expriesMoment)) return expriesMoment;
  expriesMoment = new Date(expiresAt);
  if (isValid(expriesMoment)) return expriesMoment;
  return addDays(new Date(createdAt), expiryLength);
};

export const daysUntil = (date: Date): number => {
  const currentDate = new Date(format(new Date(), 'yyyy-MM-dd'));
  const lastDate = new Date(format(new Date(date), 'yyyy-MM-dd'));
  const days = differenceInDays(lastDate, currentDate);
  if (days < 0) return 0;
  return days;
};

export const isEcomOrder = (source: OrderSource): boolean => OrderSource[source?.toString()] === OrderSource.ECOM;

export const isOldOfferExpire = (status: OrderStatus, vatField: number | null): boolean => {
  return OrderStatus?.EXPIRED === OrderStatus[status?.toString()] && vatField === null;
};

export const isNextOrder = (source: OrderSource): boolean => OrderSource[source?.toString()] === OrderSource.NEXT;

export const isCRMOrder = (source: OrderSource): boolean => OrderSource[source?.toString()] === OrderSource.CRM;

export enum ListType {
  FINANCE = 'finance',
  DETAIL = 'details',
}

export const checkStatus = (
  matchingStati: OrderStatus[],
  status: OrderStatus,
  includeCancelled = false,
  includeExpired = false,
) => {
  if (includeCancelled) matchingStati?.push(OrderStatus.CANCELLED);
  if (includeExpired) matchingStati?.push(OrderStatus.EXPIRED);

  // Sometimes `status` is sent as the string representation of the enum key.
  if (typeof status === 'string' && (status as string)?.length > 1) status = OrderStatus[status as unknown as string];

  return matchingStati?.includes(status);
};

export const filterContracts = (contracts: IBosApiAttachmentData[]): IBosApiAttachmentData[] => {
  const filteredContracts = contracts?.filter((contract) => {
    return contract?.isSigned;
  });
  return filteredContracts.sort((contractBefore, contractAfter) => {
    // @ts-ignore
    return new Date(contractAfter?.signingDate) - new Date(contractBefore?.signingDate);
  });
};

export const getMissingFieldsFromOrder = (order: IOrder, cartData: ICartSummaryData[]): string[] => {
  if (isEmpty(order) || isEmpty(order.pimSnapshot) || isEmpty(cartData) || !order?.vehicles?.[0]?.serialNo) return [];
  const pimSnapshot = order.pimSnapshot;
  const missingFields: string[] = [];

  const fieldChecks = {
    'leveringskostnader': pimSnapshot.extraDeliveryCharge,
    'sales article': pimSnapshot.salesArticle,
    'bil pris': pimSnapshot.carPriceExclVatAndFees,
    'base cost pris': pimSnapshot.baseCostPriceExclVat,
    'vrakpant': pimSnapshot.extraWreckage,
    'engangsavgift': pimSnapshot.oneOffTaxWeight,
    'fuel type': pimSnapshot.fuelType,
  };

  // Check and push missing fields
  Object.keys(fieldChecks).forEach((field) => {
    if (isNull(fieldChecks[field]) || !fieldChecks[field]) {
      missingFields.push(field);
    }
  });

  const nullFieldChecks = {
    'package pris': pimSnapshot.packagePriceExclVat,
    'package cost pris': pimSnapshot.packageCostPriceExclVat,
  };

  // Check and push missing fields with null value
  Object.keys(nullFieldChecks).forEach((field) => {
    if (isNull(nullFieldChecks[field])) {
      missingFields.push(field);
    }
  });

  cartData?.forEach((data) => {
    if (!data?.vatCode) {
      missingFields.push(`vat code av ${data?.name || ''}`);
    }
  });

  return missingFields;
};

export const getMissingFieldsFromVehicle = (vehicle: IVehicleConfiguration): string[] => {
  if (isEmpty(vehicle)) return [];

  const missingFields: string[] = [];

  // Map for field checks
  const fieldChecks = {
    'SKU': vehicle.sku,
    'make': vehicle.model?.make,
    'vehicleType': vehicle.model?.vehicleType,
    'bil pris': vehicle.pricing?.carPriceExclVatAndFees,
    'base cost pris': vehicle.pricing?.baseCostPriceExclVat,
    'fuel type': vehicle.model?.fuel?.type,
    'vrakpant': vehicle.pricing?.extraWreckage,
    'engangsavgift': vehicle.pricing?.oneOffTaxWeight,
    'leveringskostnader': vehicle.pricing?.extraDeliveryCharge,
    [`vat code av ${vehicle.model?.name || ''}`]: vehicle.pricing?.vatCode,
  };

  // Check and push missing fields
  Object.keys(fieldChecks).forEach((field) => {
    if (isNull(fieldChecks[field]) || !fieldChecks[field]) {
      missingFields.push(field);
    }
  });

  const nullFieldChecks = {
    'package pris': vehicle.pricing?.packagePriceExclVat,
    'package cost pris': vehicle.pricing?.packageCostPriceExclVat,
  };

  // Check and push missing fields with null value
  Object.keys(nullFieldChecks).forEach((field) => {
    if (isNull(nullFieldChecks[field])) {
      missingFields.push(field);
    }
  });

  const nestedChecks = [
    { items: vehicle.accessories, label: 'accessory' },
    { items: vehicle.tires, label: 'tire' },
    { items: vehicle.interiors, label: 'interiør' },
    { items: vehicle.varnishes, label: 'farge' },
    { items: vehicle.model?.selectableEquipment, label: 'tillegg' },
    { items: vehicle.model?.rims, label: 'felger' },
  ];

  nestedChecks.forEach(({ items, label }) => {
    items?.forEach((item) => {
      if (!item?.vatCode) {
        missingFields.push(`vat code av ${item?.name || label}`);
      }
    });
  });

  return missingFields;
};

export const getMissingFieldsFromVehicleModelData = (vehicleModels: IPimVehicleModel[]): string[] => {
  if (isEmpty(vehicleModels)) return [];

  const missingFields: string[] = [];

  vehicleModels.forEach((vehicleModel) => {
    const nameLabel = vehicleModel.model?.name || 'ukjent';

    const fieldChecks = {
      'motorvariant navn': nameLabel,
      [`modellkode av ${nameLabel}`]: vehicleModel.model?.modelCode,
      [`SKU av ${nameLabel}`]: vehicleModel.sku,
      [`bil pris av ${nameLabel}`]: vehicleModel.prices?.carPriceExclVatAndFees,
      [`base cost pris av ${nameLabel}`]: vehicleModel.prices?.baseCostPriceExclVat,
      [`vrakpant av ${nameLabel}`]: vehicleModel.prices?.extraWreckage,
      [`engangsavgift av ${nameLabel}`]: vehicleModel.prices?.oneOffTaxWeight,
    };
    // Check for missing fields and add to the list
    Object.keys(fieldChecks).forEach((field) => {
      if (isNull(fieldChecks[field]) || !fieldChecks[field]) {
        missingFields.push(field);
      }
    });

    const nullFieldChecks = {
      [`package pris av ${nameLabel}`]: vehicleModel.prices?.packagePriceExclVat,
      [`package cost pris av ${nameLabel}`]: vehicleModel.prices?.packageCostPriceExclVat,
    };

    // Check and push missing fields with null value
    Object.keys(nullFieldChecks).forEach((field) => {
      if (isNull(nullFieldChecks[field])) {
        missingFields.push(field);
      }
    });
  });

  return missingFields;
};

export const getMissingFields = (
  order: IOrder,
  vehicle: IVehicleConfiguration,
  vehicleModels: IPimVehicleModel[],
  cartData: ICartSummaryData[],
): string => {
  let message = '';
  let vehicleFields: string[] = [];
  if (isEmpty(vehicle)) {
    vehicleFields = getMissingFieldsFromVehicleModelData(vehicleModels);
  } else {
    vehicleFields = getMissingFieldsFromVehicle(vehicle);
  }
  const orderFields = getMissingFieldsFromOrder(order, cartData);
  const allFields = [...orderFields, ...vehicleFields];
  const finalFields = [...new Set(allFields)];
  if (finalFields?.length) {
    message = `Informasjon om ${finalFields?.join(', ')} kunne ikke hentes. `;
  }
  return message;
};