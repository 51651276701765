import { NextErrorCode } from 'next-common';

export const getErrorMessageFromNextError = (errorCode: number): string => {
  switch (errorCode) {
    case NextErrorCode.CREATE_ORDER_FAILED:
      return 'Opprettelse av ny ordre feilet';
    case NextErrorCode.CALCULATION_ADD_ERROR:
      return 'Legge til ny finansiering på ordre feilet';
    case NextErrorCode.CALCULATION_REMOVE_ERROR:
      return 'Fjerne finansiering fra ordre feilet';
    case NextErrorCode.CHOOSE_OFFER_ERROR:
      return 'Valg av finansiering feilet';
    case NextErrorCode.VEHICLE_REMOVE_ERROR:
      return 'Fjerning av kjøretøy på ordre feilet';
    case NextErrorCode.VEHICLE_ADD_ERROR:
      return 'Legge til kjøretøy på ordre feilet';
    case NextErrorCode.VEHICLE_ACCESSORY_TOGGLE_ERROR:
      return 'Endring av tilbehør feilet';
    case NextErrorCode.CUSTOMER_ADD_ERROR:
      return 'Legge til kunde feilet';
    case NextErrorCode.SIGN_CONTRACT_ERROR:
      return 'Signering av kontrakt feilet';
    case NextErrorCode.VEHICLE_RESERVE_ERROR:
      return 'Noe gikk galt med reservasjonen. Vennligst prøv igjen.';
    case NextErrorCode.RESIDUAL_MISSING_ERROR:
      return 'Vi tilbyr ikke leasing på denne modellen eller kombinasjonen av løpetid/kjørelengde.';
    case NextErrorCode.RECALCULATION_ERROR:
      return 'Omberegning mislyktes med nye gebyrer';
    default:
      return 'En ukjent feil oppstod';
  }
};
