import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import {
  isStockOrder,
  IVehiclePageProps,
  orderPlaceholder,
  PATHS,
  Routes as Path,
  useOrder,
  isDemoOrder,
} from '../common';
import { isCancelOrder, isIfsEventPending, isOrder } from '../components/sales/sales-order-view/OrderPane';
import { getAppendingNumericIdFromUrl, getIndependentDealer, isOrderEditable, isOrderInvoiced } from '../utils';
import SalesWizardMaster from './sales/sales-wizard/SalesWizardMaster';
import { IConfiguration, isEditable, OrderSourceEnum } from 'next-common';
import { IVehicleUpdate } from './sales/sales-wizard';

interface IProtectedRouteProps {
  dealerRole?: string;
  isTestUser?: boolean;
  configuration?: IConfiguration;
  manageSocket?(group?: string, vehicleUpdate?: (update: IVehicleUpdate) => void): void;
}

const ProtectedRoute = (props: IProtectedRouteProps) => {
  const URL = window.location.href;

  const isOrderNonEditableOrFinalized = (order, configuration) => {
    const isEditable = isOrderEditable(order, configuration);
    const isOrderStatus = isOrder(order?.status);
    const isStockOrderType = isStockOrder(order?.lead?.orderType);
    const isCancelled = isCancelOrder(order?.status);
    const isPendingEvent = isIfsEventPending(order?.status);
    const isInvoiced = isOrderInvoiced(order);

    return (
      (isOrderStatus && !isEditable) ||
      (isEditable && isInvoiced) ||
      (isStockOrderType && isOrderStatus) ||
      isCancelled ||
      isPendingEvent
    );
  };

  const redirectBasedOnUtstyrspakkeSelection = (order) => {
    const isTilbehorOrFinansOrSignering =
      URL?.includes('/tilbehor') || URL?.includes('/finansogtjenester') || URL?.includes('/signering');
    const isFinansOrSignering = URL?.includes('/finansogtjenester') || URL?.includes('/signering');
    const hasPimId = order?.source === OrderSourceEnum.STOCK_SALE || !!order?.orderReference?.pimId;
    const noSerialNoOrFactoryDetails = !(
      order?.vehicles?.[0]?.serialNo || order?.vehicles?.[0]?.orderFromFactoryDetails
    );

    return (
      (order && !hasPimId && isTilbehorOrFinansOrSignering) ||
      (((order && isStockOrder(order?.lead?.orderType) && isTilbehorOrFinansOrSignering) ||
        (order && isDemoOrder(order?.lead?.orderType) && isFinansOrSignering)) &&
        hasPimId &&
        noSerialNoOrFactoryDetails)
    );
  };

  const handleOrderTypeRedirection = (order) => {
    return (
      (isStockOrder(order?.lead?.orderType) && (URL?.includes('/tilbehor') || URL?.includes('/finansogtjenester'))) ||
      (isDemoOrder(order?.lead?.orderType) &&
        URL?.includes('/finansogtjenester') &&
        !!order?.chosenOffer?.id &&
        (!!order?.vehicles?.[0]?.serialNo || !!order?.vehicles?.[0]?.orderFromFactoryDetails))
    );
  };

  const [dealerRoleType, setDealerRoleType] = useState<string>('DEFAULT');
  const orderId = getAppendingNumericIdFromUrl('');
  const orderProps: IVehiclePageProps = {
    orderId: orderId,
  };
  const order = useOrder(orderProps);

  useEffect(() => {
    if (order?.user?.email) {
      getIndependentDealer(order?.user?.email)
        .then((data) => {
          if (data) {
            console.log('===== Dealer Data ===== ', data);
            setDealerRoleType('INDEPENDENT_DEALER');
          }
        })
        // tslint:disable-next-line:no-console
        .catch((err) => console.error(err));
    }
  }, [order]);

  const common = () => {
    // When the order is invoiced, canceled, or pending an event from IFS and is not editable (for STOCK orders), redirect the order to the view page.
    if (isOrderNonEditableOrFinalized(order, props?.configuration)) {
      const redirectPath = PATHS.view.replace(orderPlaceholder, orderId);
      return <Navigate to={redirectPath} />;
    }

    // If "Utstyrspakke" is not selected in step 1, redirect to the vehicle picker page.
    // For STOCK orders with "Utstyrspakke" selected, block redirection to the accessory or financing pages and redirect to the picker page.
    // For DEMO orders with "Utstyrspakke" selected, block redirection to the financing page and redirect to the picker page.
    if (redirectBasedOnUtstyrspakkeSelection(order)) {
      const redirectPath = Path.getVehiclePickerPage(order?.id, PATHS.salesWizard, order?.lead);
      return (
        <SalesWizardMaster
          configuration={props.configuration}
          manageSocket={props.manageSocket}
          dealerRole={dealerRoleType}
          isTestUser={props.isTestUser}
          redirectPath={redirectPath}
        />
      );
    }

    // For STOCK orders with an "OFFER" created, prevent redirection to the accessory or financing pages and redirect to the signing page.
    // For DEMO orders with an "OFFER" created, prevent redirection to the financing page and redirect to the signing page.
    if (handleOrderTypeRedirection(order)) {
      const redirectPath = Path.getSigningPage(order?.id, PATHS.salesWizard);
      return (
        <SalesWizardMaster
          configuration={props.configuration}
          manageSocket={props.manageSocket}
          dealerRole={dealerRoleType}
          isTestUser={props.isTestUser}
          redirectPath={redirectPath}
        />
      );
    }

    // When the order is in edit mode, prevent redirection to the vehicle picker page and redirect to the accessory page instead.
    if (isEditable(order?.status) && URL?.includes('/bil')) {
      const redirectPath = Path.getVehicleAccessoriesPage(order?.id, PATHS.salesWizard);
      return (
        <SalesWizardMaster
          configuration={props.configuration}
          manageSocket={props.manageSocket}
          dealerRole={dealerRoleType}
          isTestUser={props.isTestUser}
          redirectPath={redirectPath}
        />
      );
    }
  };

  const redirectComponent = common();
  if (redirectComponent) {
    return redirectComponent;
  }

  //By default, if none of the above conditions are met, redirect to the vehicle picker page.
  return (
    <SalesWizardMaster
      configuration={props.configuration}
      manageSocket={props.manageSocket}
      dealerRole={dealerRoleType}
      isTestUser={props.isTestUser}
    />
  );
};

export default ProtectedRoute;
