import React from 'react';
import { InfoBlockStyled } from './InfoBlock.styled';
import { formatNumber } from '@next-components/common-types';
import { Icon, IconType } from '@next-components/bos-icon';
import { bosColor } from '@next-components/common-styles';

export interface IInfoBlockProps {
  label: string;
  value?: string | number;
  children?: React.ReactNode;
  className?: string;
  updatedValue?: number;
  showWarning?: boolean;
}

export const InfoBlock = (props: IInfoBlockProps) => {
  const getFormattedValue = () => {
    if (typeof props.value === 'number') {
      return formatNumber(props.value);
    } else {
      return props.value || '-';
    }
  };

  return (
    <InfoBlockStyled className={props.className}>
      <div className='info-block-header'>
        {props.showWarning ? <Icon icon={IconType.Warning} height={24} width={24} color={bosColor.morkOker} />: null}
        <span className="label">{props.label}</span>
      </div>
      <div className='info-prices'>
        {props.updatedValue ? <span className="value">{`${formatNumber(props.updatedValue ?? 0)} kr`}</span> : null}
        {props.value && <span className={`value ${props.updatedValue ? 'strike-out': ''}`}>{getFormattedValue()}</span>}
      </div>
      {props.children}
    </InfoBlockStyled>
  );
};
